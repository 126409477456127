Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.apiContentTypeAppJson = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.postApiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfmaterialitymatrixgraph0";
exports.labelBodyText = "cfmaterialitymatrixgraph0 Body";
exports.btnExampleTitle = "CLICK ME";
exports.getMaterialityMatrixChartDataApiEndPoint = "bx_block_dashboard/get_materiality_matrix_data";
exports.bubbleChartOptions = {
  chart: {
    type: "bubble", // Changed type to bubble
    zoom: {
      enabled: false,
      type: "xy",
    },
    // background: "lightgrey",
  },
  colors: ["#6EEA95", "#FD9568", "#AB9EFF"],
  xaxis: {
    type: "numeric",
    title: {
      text: "Importance to Company",
      style: {
        color: "#7B7D7F",
        fontSize: '14px',
        fontFamily: 'Lato',
        fontWeight: 700,
      },
    },
    stepSize: 1,
    min: -1,
    max: 11,
    tickAmount: 13,
    labels: {
      style: {
        fontFamily: "Lato",
        fontSize: "12px",
        fontWeight: 700,
      },
      formatter: function (value) {
        if (value === 1) return "Moderate";
        if (value === 6) return "Significant";
        if (value === 11) return "Major";
        return "";
      },
    },
  },
  yaxis: {
    title: {
      text: "Importance to Stakeholders",
      style: {
        color: "#7B7D7F",
        fontSize: '14px',
        fontFamily: 'Lato',
        fontWeight: 700,
      },
    },
    min: -1,
    max: 11,
    stepSize: 1,
    labels: {
      style: {
        fontFamily: "Lato",
        fontSize: "12px",
        fontWeight: 700,
      },
      formatter: function (value) {
        if (value === 1) return "Low";
        if (value === 5) return "Medium";
        if (value === 9) return "High";
        return "";
      },
    },
  },
  grid: {
    yaxis: {
      lines: {
        show: true, // Disable horizontal grid lines
      },
    },
    xaxis: {
      lines: {
        show: true, // Optional: Retain vertical grid lines if needed
      },
    },
    row: {
      colors: ["#F8FAFC", "#F8FAFC", "#F8FAFC"], // Background color inside the chart
      opacity: 1, // Set opacity (1 for solid color)
    },
    column: {
      colors: ["#F8FAFC", "#F8FAFC", "#F8FAFC"], // Background color for columns
      opacity: 1, // Set opacity (1 for solid color)
    },
  },
  annotations: {
    // Draws vertical lines 
    xaxis: [
      {
        x: -1,
        x2: -0.9,
        fillColor: "#E1E1E1",
        opacity: 1,
        strokeDashArray: 0,
        borderColor: "#E1E1E1",
      },
      {
        x: 3,
        strokeDashArray: 0,
        borderColor: "rgba(236, 234, 234, 0.5)",
      },
      {
        x: 7,
        strokeDashArray: 0,
        borderColor: "rgba(236, 234, 234, 0.5)",
      },
      {
        x: 11,
        strokeDashArray: 0,
        borderColor: "rgba(236, 234, 234, 0.5)",
      },
    ],
    // Draws horizontal lines 
    yaxis: [
      {
        y: -1,
        y2: -0.8,
        fillColor: "#E1E1E1",
        opacity: 1,
        strokeDashArray: 0,
        borderColor: "#E1E1E1",
      },
      {
        y: 3,
        strokeDashArray: 0,
        borderColor: "rgba(236, 234, 234, 0.5)",
      },
      {
        y: 7,
        strokeDashArray: 0,
        borderColor: "rgba(236, 234, 234, 0.5)",
      },
      {
        y: 11,
        strokeDashArray: 0,
        borderColor: "rgba(236, 234, 234, 0.5)",
      },
    ],
  },
  dataLabels: {
    enabled: true,
    formatter: function (val, opts) {
      const point =
        opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
      if (point) {
        return point.label;
      } else {
        return "";
      }
    },
    textAnchor: "start",
    // offsetX: 20,
    offsetY: 10,
    style: {
      fontSize: '12px',
      fontFamily: 'Lato',
      fontWeight: '600',
      colors: ["#7B7D7F"], // Set the label color
    },
    background: {
      enabled: true,
      opacity: 1,
    }
  },
  tooltip: {
    custom: function ({ seriesIndex, dataPointIndex, w }) {
      const label = w.config.series[seriesIndex].data[dataPointIndex].label;
      const score = w.config.series[seriesIndex].data[dataPointIndex].z;
      return `<div style="font-family: Lato;  font-size: 14px;  font-weight: 400;  line-height: 22px;  color: #000;  padding: 5px;">
                <b>${label}</b><br/>
                Score: ${score}
              </div>`;
    },
  },
  legend: {
    show: true,
    position: "top",
    horizontalAlign: "left",
    fontSize: '11px',
    fontFamily: 'Lato',
    fontWeight: 700,
    offsetX: -36,
    labels: {
      colors: ["#7B7D7F", "#7B7D7F", "#7B7D7F"]
    }
  },
  plotOptions: {
    bubble: {
      minBubbleRadius: 6,
      maxBubbleRadius: 18,
    }
  },
  responsive: [
    {
      breakpoint: 600,
      options: {
        chart: {
          offsetY: 10,
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
        }
      }
    }
  ]
};

// Mock api response
exports.mockEmptyMaterialMatrixResponse = {
  "series": [],
  "frameworks": [
      {
          "id": 1,
          "name": "B-CORP"
      },
      {
          "id": 2,
          "name": "GHG Protocol"
      },
      {
          "id": 3,
          "name": "GRI 303-5"
      },
      {
          "id": 4,
          "name": "GRI 304-2"
      },
      {
          "id": 5,
          "name": "GRI 102-26"
      },
      {
          "id": 12,
          "name": "GRI"
      }
  ],
  "success": true
}

exports.mockEmptyMaterialMatrixResponse2 = {
  "series": [
    {
        "name": "Environment",
        "data": []
    },
    {
        "name": "Social",
        "data": []
    },
    {
        "name": "Governance",
        "data": []
    }
  ],
  "frameworks": [
      {
          "id": 1,
          "name": "B-CORP"
      },
      {
          "id": 2,
          "name": "GHG Protocol"
      },
      {
          "id": 3,
          "name": "GRI 303-5"
      },
      {
          "id": 4,
          "name": "GRI 304-2"
      },
      {
          "id": 5,
          "name": "GRI 102-26"
      },
      {
          "id": 12,
          "name": "GRI"
      }
  ],
  "success": true
}

exports.mockMaterialMatrixResponse = {
  "series": [
      {
          "name": "Governance",
          "data": [
              {
                  "x": 4,
                  "y": 6,
                  "z": 100,
                  "label": "Anti-Corruption Code of Conduct"
              },
              {
                  "x": 4,
                  "y": 4,
                  "z": 100,
                  "label": "Regulatory Compliance"
              }
          ]
      },
      {
          "name": "Environment",
          "data": [
              {
                  "x": 4,
                  "y": 0,
                  "z": 0,
                  "label": "Water"
              },
              {
                  "x": 7,
                  "y": 6,
                  "z": 90,
                  "label": "Emissions"
              },
              {
                  "x": 5,
                  "y": 5,
                  "z": 62,
                  "label": "Effluents \u0026 Waste"
              }
          ]
      },
      {
          "name": "Social",
          "data": [
              {
                  "x": 4,
                  "y": 10,
                  "z": 66,
                  "label": "Employment Practices"
              },
              {
                  "x": 9,
                  "y": 4,
                  "z": 85,
                  "label": "Occupational Health \u0026 Safety"
              },
              {
                  "x": 5,
                  "y": 6,
                  "z": 50,
                  "label": "Training \u0026 Education"
              }
          ]
      }
  ],
  "frameworks": [
      {
          "id": 1,
          "name": "B-CORP"
      },
      {
          "id": 2,
          "name": "GHG Protocol"
      },
      {
          "id": 3,
          "name": "GRI 303-5"
      },
      {
          "id": 4,
          "name": "GRI 304-2"
      },
      {
          "id": 5,
          "name": "GRI 102-26"
      },
      {
          "id": 12,
          "name": "GRI"
      }
  ],
  "success": true
}
// Customizable Area End