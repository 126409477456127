import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
  Paper,
  TextField,
  Radio,
  Chip
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider,styled } from "@material-ui/core/styles";
import Layout from "../../dashboard/src/Layout.web";
import CustomizedSteppers from "../../../components/src/CustomizedSteppers";
import CloseIcon from '@material-ui/icons/Close';
import {checkbox, checked} from "../../customform/src/assets"
import Autocomplete from "@material-ui/lab/Autocomplete";
import {closeChip} from "./assets"
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
const theme = createTheme({
  typography: {
    fontFamily:'Lato'
  },
});

const CustomTableContainer = styled(TableContainer)({
	// minHeight: "583px",
	display: 'flex',
	justifyContent: 'space-between',
	flexDirection: 'column',
	padding: '12px 24px',
	paddingLeft: 0,
	'@media(max-width: 600px)': {
		padding: 0
	}
});

const CustomTable = styled(Table)({
	width: "100%",
	borderCollapse: "separate",
});
const CustomTableHead = styled(TableHead)({
	backgroundColor: "#F5F5F5",
	borderRadius: "12px",
});
const CustomTableRowHead = styled(TableRow)({
	// borderRadius: "20px !important",
	overflow: "hidden",
	"& :first-child": {
		borderTopLeftRadius: 10,
		borderBottomLeftRadius: 10,
	},
	"& :last-child": {
		borderTopRightRadius: 10,
		borderBottomRightRadius: 10,
	},
});
const CustomTableCellHead = styled(TableCell)({
	minHeight: "48px",
	padding: "10px",
	flexShrink: 0,
	borderBottom: "none",
	color: "#7D7D7F",
	fontFamily: "Lato",
	fontSize: "14px",
	fontStyle: "normal",
	fontWeight: 700,
	lineHeight: "21px",
	letterSpacing: "0.2px",
});

const CustomTableCellHeadUsername = styled(TableCell)({
	minHeight: "48px",
	padding: "10px 18px",
	flexShrink: 0,
	borderBottom: "none",
	color: "#7D7D7F",
	fontFamily: "Lato",
	fontSize: "14px",
	fontStyle: "normal",
	fontWeight: 700,
	lineHeight: "21px",
	letterSpacing: "0.2px",
});
const CustomSpacing = styled("div")({
	height: 16,
	display: "table-header-group",
});
const CustomTableBody = styled(TableBody)({
	color: "inherit",
});
const CustomTableRow = styled(TableRow)({});
const CustomTableCellUsername = styled(TableCell)({
	borderBottom: "1px solid #E3E3E3",
	padding: "8px 8px 8px 18px",
	color: "#34373A",
	fontFamily: "Lato",
	fontSize: "14px",
	fontStyle: "normal",
	fontWeight: 700,
	lineHeight: "21px",
	letterSpacing: "0.2px",
});
const CreateButton = styled(Button)({
  color:'#43D270',
  fontSize:'12px',
  fontWeight:700,
  textTransform:'capitalize' as 'capitalize',
  '& :hover':{
    color:'#43D270',
  }
})

const CreateTaskButton = styled(Button)({
  background:'#43D270',
  borderRadius:'8px',
  color:'#34373A',
  fontSize:'12px',
  fontWeight:700,
  textTransform:'capitalize' as 'capitalize',
  boxShadow:'none',
  width:'195px',
  height:'40px',
  '&:hover':{
    background:'#43D270',
    color:'#34373A',
    boxShadow:'none'
  }
})

const TaskNameField = styled(TextField)({
  width:'100%',
    fontSize:'14px',
    color:'#34373A',
  '& div':{
    borderRadius:'8px',
    '& :focus':{
      outline:'auto',
      borderRadius:'8px',
    },
  }
})

const options = [
  { value: 'option1', label: 'Option 1' },
  { value: 'option2', label: 'Option 2' },
  { value: 'option3', label: 'Option 3' },
];

const assigneeOption = [
  {value:'robin',label:'Robin'},
  {value:'test',label:'Test1'},
  {value:'test2',label:'Test2'},
  {value:'test3',label:'Test3'}
]

const criticalOptions = [
  {value:'blocker',label:'Blocker'},
  {value:'critical',label:'Critical'}
]

const renderSubCategory = (option: any, { selected }: any) => {
  return (
    <li style={{ maxHeight: "18px", display: 'flex', alignItems: 'center', padding: 0 }}>
      <Radio
        icon={<img src={checkbox} />}
        checkedIcon={<img src={checked} />}
        style={{ marginLeft: -16 }}
        checked={selected}
      />
      <Typography style={{ ...webStyle.optionName, color: selected ? "#34373A" : "#7D7D7F", }}>{option.label}</Typography>
    </li>
  );
}
const renderInput = (params: any) => {
  return (
      <TextField
          {...params}
          name="memberlist"
          placeholder="Select one from the list"
          InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: webStyle.autoComplete,
          }}
      />
  );
}

// Customizable Area End

import GoalManagementController, {
  Props,
  configJSON,
} from "./GoalManagementController";

export default class GoalManagement extends GoalManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
        return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Layout>
          <Container style={webStyle.mainWrapper}>
              <Box>
                <Typography style={webStyle.actionText}>Action Plan</Typography>
                <Typography style={webStyle.actionDescription}>Copy that talks about what time means to the users and some guidance or instructions or what to make from this and how to proceed forward. This  talks about what time means to the users and some guidance or instructions or what to make from this and how to proceed forward.</Typography>
              </Box>
             <CustomizedSteppers
             data-testid="customized-stepper"
              getSteps={this.state.categories}
              getStepContent={this.getStepContent}
              activeStep={this.state.activeStep}
             />
             <CustomTableContainer>
             <CustomTable aria-label="task table">
             <CustomTableHead>
             <CustomTableRowHead>
                <CustomTableCellHeadUsername>
                Task Name
								</CustomTableCellHeadUsername>
								<CustomTableCellHead>Sub Category</CustomTableCellHead>
								<CustomTableCellHead>Assignee</CustomTableCellHead>
								<CustomTableCellHead>Criticality</CustomTableCellHead>
								<CustomTableCellHead>Start Date</CustomTableCellHead>
								<CustomTableCellHead>End Date</CustomTableCellHead>
             </CustomTableRowHead>
             </CustomTableHead>
              {this.state.tableData.length > 0 &&
              <>
             <CustomSpacing />
             <CustomTableBody>
              <CustomTableRow>
              <CustomTableCellUsername></CustomTableCellUsername>
              </CustomTableRow>
             </CustomTableBody>
              </>
              }
             </CustomTable>
             </CustomTableContainer>
             {this.state.tableData.length > 0 ?
             <div>
            </div> :
            <div style={webStyle.creatButtonDiv}>
              <Typography style={webStyle.noTaskText}>{configJSON.noTask}</Typography>
              <CreateButton
              onClick={this.handleCreateTaskForm}
              data-testid="create-button"
              >{configJSON.createButton}</CreateButton>
            </div>
             }
          </Container>
          <Modal 
          open={this.state.createTaskForm} 
          onClose={this.handleCloseCreateTaskForm} 
          style={webStyle.modalWrapper} 
          data-testid="task-modal"
          >
          <Paper style={webStyle.mdalPaper}>
            <Box style={webStyle.createModalFirstDiv}>
              <Typography style={webStyle.createTaskText}>{configJSON.CreateTask}</Typography>
              <CloseIcon onClick={this.handleCloseCreateTaskForm}/>
            </Box>
            <Typography style={webStyle.createTaskDescr}>{configJSON.createTaskDescription}</Typography>
            <Box style={webStyle.textFiledBox}>
              <Box style={webStyle.fieldBox}>
             <Typography style={webStyle.taskNameLabel}>{configJSON.taskName}</Typography>
              <TaskNameField
              placeholder={configJSON.taskNamePlaceholder}
              variant="outlined"
              onChange={this.handleChangeTaskName}
              value={this.state.taskNameValue}
              data-testid="task-name"
              />
              </Box>
              <Box style={webStyle.fieldBox}>
                <Typography style={webStyle.taskNameLabel}>{configJSON.selectSubCategory}</Typography>
              <Autocomplete
                    id="subcategory"
                    options={options}
                    disableCloseOnSelect
                    data-testid="sub-category"
                    onChange={(event, value) => this.setState({subCategoryValue:value?.value})}
                    closeIcon={false}
                    popupIcon={<div style={{ margin: '5px 12px' }}>
                        <KeyboardArrowDownIcon/>
                    </div>}
                    renderInput={renderInput}
                    renderOption={renderSubCategory}

                    PopperComponent={({ children, ...other }) => (
                        <div {...other} style={webStyle.modelpopper}>
                            {children}
                        </div>
                    )}
                    getOptionLabel={(option: any) => option.label}
                />
              </Box>
            </Box>
            <Box style={webStyle.textFiledBox}>
            <Box style={webStyle.fieldBoxAssing}>
            <Typography style={webStyle.taskNameLabel}>{configJSON.SelectAssignee}</Typography>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              data-testid="assignee-field"
              options={assigneeOption}
              disableCloseOnSelect
              onChange={(event, value) => this.setState({assigneeValue:value})}
              closeIcon={false}
              popupIcon={<div style={webStyle.arrowDiv}>
                        <KeyboardArrowDownIcon/>
                    </div>}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    label={option.label}
                    {...getTagProps({ index })}
                    style={{
                      background:'#ECFBF1',
                      borderRadius:'8px',
                      color:'#329E54',
                      fontSize:'12px',
                      fontWeight:500,
                      fontFamily:'Lato',
                      padding:'10px 3px'
                    }}
                    deleteIcon={<img src={closeChip} style={{width:'14px'}}/>}
                  />
                ))
              }
              PopperComponent={({ children, ...other }) => (
                  <div {...other} style={webStyle.modelpopper}>
                      {children}
                  </div>
              )}
              getOptionLabel={(option) => option.label}
              renderInput={renderInput}
              renderOption={renderSubCategory}
            />
            </Box>
            <Box style={webStyle.dateMainBox}>
            <Box style={webStyle.fieldBox}>
            <Typography style={webStyle.taskNameLabel}>{configJSON.StartDate}</Typography>
            <TaskNameField
              variant="outlined"
              type="date"
              data-testid="start-date"
              onChange={this.handleStartDate}
              value={this.state.startDate}
              />
            </Box>
            <Box style={webStyle.fieldBox}>
            <Typography style={webStyle.taskNameLabel}>{configJSON.EndDate}</Typography>
            <TaskNameField
              variant="outlined"
              type="date"
              data-testid="end-date"
              onChange={this.handleChangeEndDate}
              value={this.state.endDate}
              />
            </Box>
            </Box>
            </Box>
            <Box style={webStyle.textFiledBox}>
            <Box style={webStyle.fieldBoxAssing}>
            <Typography style={webStyle.taskNameLabel}>{configJSON.SelectCriticality}</Typography>
              <Autocomplete
                    id="subcategory"
                    options={criticalOptions}
                    data-testid="critical-field"
                    onChange={(event, value) => this.setState({criticalValue:value?.value})}
                    disableCloseOnSelect
                    closeIcon={false}
                    popupIcon={<div style={{ margin: '5px 12px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M15 7.5L10 12.5L5 7.5" stroke="#7D7D7F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>}
                    PopperComponent={({ children, ...other }) => (
                        <div {...other} style={webStyle.modelpopper}>
                            {children}
                        </div>
                    )}
                    getOptionLabel={(option: any) => option.label}
                    renderOption={renderSubCategory}
                    renderInput={renderInput}
                />
            </Box>
            </Box>
            <Box style={webStyle.textFiledBox}>
            <Box style={webStyle.fieldBox}>
            <Typography style={webStyle.taskNameLabel}>{configJSON.Description}</Typography>
              <TaskNameField
              placeholder={configJSON.descriptionPlaceholder}
              variant="outlined"
              data-testid="description"
              onChange={this.handleChangeDescription}
              value={this.state.description}
              />
            </Box>
            </Box>
            <Box style={webStyle.buttonBox}>
              <CreateTaskButton>{configJSON.CreateTask}</CreateTaskButton>
            </Box>
          </Paper>
        </Modal>
        </Layout>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    margin:'50px 20px 20px'
  },
  actionText:{
    fontSize:'22px',
    fontWeight:700,
    color:'#34373A',
    lineHeight:'27.5px'
  },
  actionDescription:{
    fontSize:'12px',
    color:'#7D7D7F',
    lineHeight:'19.2px',
    width:'70%',
    margin:'10px 0'
  },
  noTaskText:{
    fontSize:'10px',
    fontWeight:500,
    lineHeight:'16px',
    color:'#7D7D7F',
  },
  creatButtonDiv:{
    textAlign:'center' as 'center',
    marginTop:'40px'
  },
  modalWrapper:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mdalPaper:{
    width: '100%',
    maxWidth: "899px",
    padding: '30px',
    borderRadius: '12px',
    overflow: 'scroll' as 'scroll',
    maxHeight: '90vh',
    scrollbarWidth: 'none' as 'none',
    msOverflowStyle: 'none' as 'none'
  },
  createModalFirstDiv:{
    display:'flex',
    justifyContent:'space-between'
  },
  createTaskText:{
    fontSize:'18px',
    fontWeight:700,
    lineHeight:'25.2px',
    color:'#34373A'
  },
  createTaskDescr:{
    fontSize:'14px',
    lineHeight:'24px',
    color:'#34373A',
    padding:'5px 0'
  },
  textFiledBox:{
    display:'flex',
    gap:'20px',
    marginTop:'15px'
  },
  taskNameLabel:{
    fontFamily:'Lato',
    fontSize:'15px',
    marginBottom:'5px'
  },
  fieldBox:{
    width:'100%'
  },
  fieldBoxAssing:{
    width:'50%'
  },
  optionName:{
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    fontSize: '14px'
  },
  modelpopper: {
    maxHeight: '271px',
    borderRadius: "8px",
    border: "1px solid #D9D9D9",
    background: "#FFF",
    borderBottom:'none',
    maxWidth: '404px',
    boxShadow: 'none !important',
    padding: '0px !important',
},
autoComplete: {
  maxWidth: '404px',
  minHeight: "48px",
  borderRadius: '8px',
  border: "1px solid #D9D9D9",
  padding: '12px',
  boxShadow: 'none'
},
dateMainBox:{
  display:'flex',
  gap:'20px',
  width:'50%'
},
buttonBox:{
  textAlign:'center' as 'center',
  margin:'40px 0 10px'
},
arrowDiv:{ 
  margin: '5px 12px' 
}
};
// Customizable Area End
