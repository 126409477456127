Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "GoalManagement";
exports.labelBodyText = "GoalManagement Body";

exports.btnExampleTitle = "CLICK ME";
exports.noTask = "No task’s has been created yet";
exports.createButton = "+ Create Task";
exports.CreateTask = "Create Task";
exports.createTaskDescription = "Quickly add a new task to your project. Set a title, due date, and priority to stay on track.";
exports.getCategoryEndpoint = "bx_block_goalmanagement/get_climatic_goals";
exports.taskNamePlaceholder = "Enter Task Name";
exports.taskName = "Task Name";
exports.selectSubCategory = "Select Sub Category";
exports.SelectAssignee = "Select Assignee";
exports.StartDate = "Start Date";
exports.EndDate = "End Date";
exports.SelectCriticality = "Select Criticality";
exports.Description = "Description";
exports.descriptionPlaceholder = "Enter description";
// Customizable Area End