import React, { Component } from 'react';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import HomeScreen from '../../components/src/HomeScreen';
import { createTheme, MuiThemeProvider, ThemeProvider } from '@material-ui/core/styles';

import BlogPostsManagement from '../../blocks/BlogPostsManagement/src/BlogPostsManagement';
import JobDetailWeb from '../../blocks/BlogPostsManagement/src/JobDetail.web';
import MultipageForms from '../../blocks/MultipageForms2/src/MultipageForms2.web';
import ActivAccount from '../../blocks/email-account-registration/src/ActivAccount.web';
import ContactSuccessPage from '../../blocks/DynamicContent/src/ContactUs/ContactSuccess';
import BlogDetails from '../../components/src/BlogPages/BlogDetails.web';
import Header from '../../blocks/DynamicContent/src/Header.web';
import Footer from '../../blocks/DynamicContent/src/Footer.web';
import JobApplicationPage from '../../blocks/DynamicContent/src/Jobs/JobApplicationPage.web';
import JobSuccess from "../../blocks/DynamicContent/src/Jobs/JobSuccess.web";
import PageNotFound from "../../blocks/DynamicContent/src/404Page.web";
import DynamicContent from '../../blocks/DynamicContent/src/DynamicContent.web';
import SignUp from '../../blocks/DynamicContent/src/SignUp/SignUp.web';
import Login from '../../blocks/DynamicContent/src/SignUp/Login.web';
import ForgotPasswordweb from '../../blocks/DynamicContent/src/SignUp/ForgotPassword.web';
import BlogPostsManagementWeb from '../../blocks/BlogPostsManagement/src/BlogPostsManagement.web';
import FaqListing from '../../blocks/DynamicContent/src/FaqDocument.web'
import PrivacyPolicy from '../../blocks/DynamicContent/src/PrivacyPolicy.web'
import ResendEmail from '../../blocks/forgot-password/src/ResendEmail.web';
import NewPasswordWeb from '../../blocks/forgot-password/src/NewPassword.web';
import VerifyEmail from '../../blocks/email-account-registration/src/VerifyEmail.web';
import ResendActivation from '../../blocks/email-account-registration/src/VerifyEmail.web';
import GettingStarted from '../../blocks/dashboard/src/GettingStarted.web';
import MapOrganization from '../../blocks/dashboard/src/pages/MapOrganization.web';
import LogEsgFactors from '../../blocks/dashboard/src/pages/LogEsgFactors.web';
import EsgFactorsDetails from '../../blocks/dashboard/src/pages/EsgFactorsDetails.web';
import Overview from '../../blocks/dashboard/src/Overview.web';
import ActionPlan from '../../blocks/multiplelandingpage2/src/pages/ActionPlan.web';
import Hub from '../../blocks/multiplelandingpage2/src/Hub.web';
import Reports from '../../blocks/multiplelandingpage2/src/Report.web';
import ExpertSupport from '../../blocks/appointmentmanagement/src/ExpertSupport.web';
import MarketPlaceDetails from '../../blocks/vendoruseraccountmarketplacehyperlinks/src/MarketPlaceDetails.web';
import ForecastAnalysis from '../../blocks/dashboard/src/pages/ForecastAnalysis.web'
import PlanAndBilling from '../../blocks/customisableusersubscriptions/src/PlanAndBilling.web'
import ProfilePage from '../../blocks/dashboard/src/pages/ProfilePage.web'
import PaymentStatus from '../../blocks/customisableusersubscriptions/src/PaymentStatus.web'
import Emissions from '../../blocks/cfnzdpuscope1emissions/src/Emissions.web'
import UnsubscribeNewsLetter from "../../blocks/EmailNotifications2/src/UnsubscribeNewsletter";
import NewsLetterSubscriptionSuccessPage from '../../blocks/EmailNotifications2/src/NewsLetterSubscriptionSuccessPage';
import Cfunsdgs from "../../blocks/cfunsdgs/src/Cfunsdgs.web";
import ClimatiqBasicEstimatePage from "../../blocks/cfclimatiqbasicestimate/src/Cfclimatiqbasicestimate.web";
import CfclimatiqshippingfreightPage from "../../blocks/cfclimatiqshippingfreight/src/Cfclimatiqshippingfreight.web";
import Cfintermodaldatavisualizationandinteraction from "../../blocks/cfintermodaldatavisualizationandinteraction/src/Cfintermodaldatavisualizationandinteraction.web";
import AccessRestrictedPage from "../../blocks/dashboard/src/pages/AccessRestrictedPage.web";
import { setStorageData } from '../../framework/src/Utilities';
import GoalManagement from "../../blocks/GoalManagement/src/GoalManagement.web"

const theme = createTheme({
  typography: {
    fontFamily: 'Lato, sans-serif'
  }
});

const routeMap = {
  Home: {
    component: DynamicContent,
    path: '/',
    exact: true
  },

  ContactUsPage: {
    exact: true,
    component: DynamicContent,
    path: '/contact-us'
  },

  ContactSuccess: {
    exact: true,
    component: ContactSuccessPage,
    path: '/success/:page'
  },

  PricingPage: {
    exact: true,
    component: DynamicContent,
    path: '/pricing'
  },

  OfferingsPage: {
    exact: true,
    component: DynamicContent,
    path: '/offerings'
  },

  Toolkit: {
    exact: true,
    component: DynamicContent,
    path: '/toolkit'
  },

  NewsRoomPage: {
    exact: true,
    component: BlogPostsManagement,
    path: '/news-room'
  },

  marketplace: {
    exact: true,
    component: BlogPostsManagementWeb,
    path: '/marketplace',
  },

  marketplaceDetails: {
    exact: true,
    component: BlogPostsManagementWeb,
    path: '/marketplace/:id'
  },

  OfferingsPage1: {
    exact: true,
    component: DynamicContent,
    path: '/carbonzeroed-software'
  },

  OfferingsPage2: {
    exact: true,
    component: DynamicContent,
    path: '/advisory-services'
  },

  OfferingsPage3: {
    exact: true,
    component: DynamicContent,
    path: '/implementation-services'
  },

  Industries: {
    exact: true,
    component: DynamicContent,
    path: '/industries'
  },

  events: {
    component: BlogPostsManagementWeb,
    path: '/events',
    exact: true
  },

  termsandconditions: {
    component: PrivacyPolicy,
    path: '/terms-and-conditions',
    exact: true
  },

  pivacypolicy: {
    component: PrivacyPolicy,
    path: '/privacy-policy',
    exact: true
  },
  company: {
    component: DynamicContent,
    path: '/company',
    exact: true
  },

  eventsDetails: {
    exact: true,
    component: BlogPostsManagementWeb,
    path: '/events/:id'
  },

  insights: {
    component: BlogPostsManagementWeb,
    path: '/insights-and-trends',
    exact: true
  },

  insightsDetails: {
    exact: true,
    component: BlogPostsManagementWeb,
    path: '/insights-and-trends/:id'
  },

  BlogDetails: {
    exact: true,
    component: BlogDetails,
    path: '/details'
  },

  FreeAssessmentPage: {
    exact: true,
    component: MultipageForms,
    path: '/free-assessment'
  },

  SignUp: {
    component: SignUp,
    path: '/signup'
  },

  Login: {
    component: Login,
    path: '/login/:token'
  },

  ForgotPassword: {
    component: ForgotPasswordweb,
    path: '/forgot-password'
  },

  ResendEmail: {
    component: ResendEmail,
    path: '/ResendEmail'
  },

  NewPassword: {
    component: NewPasswordWeb,
    path: '/NewPassword/:token'
  },

  VerifyEmail: {
    component: VerifyEmail,
    path: '/VerifyEmail'
  },
  ResendActivation: {
    component: ResendActivation,
    path: '/resend_mail/:mail'
  },

  ActiveAccount: {
    component: ActivAccount,
    path: '/activate_account/:token'
  },

  faq: {
    exact: true,
    component: FaqListing,
    path: '/faqs',
  },

  documents: {
    exact: true,
    component: FaqListing,
    path: '/documentation'
  },

  careers: {
    exact: true,
    component: DynamicContent,
    path: '/careers'
  },

  careerDetail: {
    exact: true,
    component: JobDetailWeb,
    path: '/careers/:id/:type'
  },
  JobOpeningsList: {
    exact: true,
    component: BlogPostsManagementWeb,
    path: '/job-openings-list'
  },

  JobSuccess: {
    exact: true,
    component: JobSuccess,
    path: '/job-success'
  },

  JobDetails: {
    exact: true,
    component: BlogPostsManagementWeb,
    path: '/job-openings-list/:id'
  },

  JobApplicationDetail: {
    component: JobApplicationPage,
    path: "/job-application/:name"
  },

  GettingStarted: {
    exact: true,
    component: GettingStarted,
    path: '/get-started'
  },

  MapOrganization: {
    component: MapOrganization,
    path: '/get-started/map-your-organization/:orgTab'
  },

  UNSDGS: {
    component: Cfunsdgs,
    path: '/measure/all-unsdg-goals'
  },

  LogEsgFactors: {
    component: LogEsgFactors,
    path: '/measure/log-your-esg-factors/:category'
  },

  EsgFactorsDetails: {
    component: EsgFactorsDetails,
    path: '/measure/log-your-esg-factor/details/:id/:mid/:cid/:card_id/:coreFacId/:status',
  },

  ClimatiqBasicEstimate: {
    component: ClimatiqBasicEstimatePage,
    path: '/measure/log-your-esg-factor/energy-consumption',
  },

  Cfclimatiqshippingfreight: {
    component: CfclimatiqshippingfreightPage,
    path: '/measure/log-your-esg-factor/shipping-and-freight',
  },

  Overview: {
    component: Overview,
    path: '/overview/dashboard',
    exact: true
  },

  ActionPlan: {
    component: ActionPlan,
    path: '/act/action-plan',
    exact: true
  },

  GoalManagement: {
    component: GoalManagement,
    path: '/measure/climatic-goals',
    exact: true
  },

  Reports: {
    component: Reports,
    path: '/transform/reporting/:orgTab',
    exact: true
  },

  Hub: {
    component: Hub,
    path: '/transform/hub/:orgTab',
    exact: true
  },

  ProfilePage: {
    component: ProfilePage,
    path: '/settings/:orgTab',
    exact: true
  },

  ExpertSupport: {
    component: ExpertSupport,
    path: '/climate-concierge/:orgTab',
  },

  MarketPlaceDetails: {
    component: MarketPlaceDetails,
    path: '/transform/hub/marketplace/details/:id',
    exact: true
  },

  Forecast: {
    component: ForecastAnalysis,
    path: '/act/forecast-Analysis',
    exact: true
  },

  PlanAndBilling: {
    component: PlanAndBilling,
    path: '/settings/plan&billing/:orgTab',
    exact: true
  },

  PaymentStatus: {
    component: PaymentStatus,
    path: '/payment/:status',
    exact: true
  },

  Emissions: {
    component: Emissions,
    path: '/transform/hubdetail/industry-benchmarking',
    exact: true
  },

  Unsubscribe: {
    component: UnsubscribeNewsLetter,
    path: '/unsubscribe',
    exact: true
  },

  NewsLetterSubscriptionSuccess: {
    component: NewsLetterSubscriptionSuccessPage,
    path: '/subscription-success',
    exact: true
  },

  Intermodal: {
    component: Cfintermodaldatavisualizationandinteraction,
    path: '/intermodal',
    exact: true
  },

  DynamicPages: {
    exact: true,
    component: DynamicContent,
    path: '/dynamicpages/:page'
  },

  AccessRestricted: {
    component: AccessRestrictedPage,
    path: '/access-restricted'
  },

  PageNotFound: {
    component: PageNotFound,
    path: '*'
  },

  AboutUs: {
    component: DynamicContent,
    path: '/about-us'
  },
};

setStorageData('routeMap', JSON.stringify(routeMap));

class App extends Component {
  render() {

    const isMobile = window.innerWidth < 600;
    const footerPathname = [
      "/job-application",
      "/get-started/",
      "/dashboard",
      "/job-success",
      "/success/email",
      "/free-assessment",
      "/act/action-plan",
      "/success/contact",
      "/signup",
      "/login/primary",
      "/forgot-password",
      "/ResendEmail",
      "/VerifyEmail",
      "/resend_mail/:mail",
      "/transform/reporting/reports",
      "/transform/reporting-framework",
      "/transform/hub/integrations",
      "/activate_account",
      "/get-started",
      "/login/secondary",
      "/NewPassword/primary",
      "/VisualAnalytics",
      "/overview/dashboard",
      "/settings/plan&billing/",
      "/payment/success",
      "/payment/fail",
      "/transform/hub/industry-benchmarking",
      "/transform/hubdetail/industry-benchmarking",
      "/transform/hub/marketplace",
      "/transform/hub/materiality-matrix",
      "/transform/hub/marketlace/details",
      "/transform/reporting/reporting-framework",
      "/climate-concierge/portfolio-manager",
      "/climate-concierge/ESG-data-consultant",
      "/climate-concierge/sustainability-advisor",
      "/act/forecast-Analysis",
      "/settings/organization-info",
      "/settings/profile",
      "/unsubscribe",
      "/subscription-success",
      "/measure/all-unsdg-goals",
      "/transform/reporting/selected-unsdgs",
      "/intermodal",
      "/measure/log-your-esg-factors/all",
      "/measure/log-your-esg-factors/all/",
      "/measure/log-your-esg-factors/core",
      "/measure/log-your-esg-factors/core/",
      "/measure/log-your-esg-factors/environment",
      "/measure/log-your-esg-factors/social",
      "/measure/log-your-esg-factors/governance",
      "/measure/log-your-esg-factors/completed",
      "/measure/log-your-esg-factors/archived",
      "/measure/log-your-esg-factor/energy-consumption",
      "/measure/log-your-esg-factor/shipping-and-freight",
      "/measure/climatic-goals",
      "/settings/plan&billing/select-plan",
      "/settings/plan&billing/confirm-billing",
      "/settings/plan&billing/invoices",
      "/settings/users",
      "/get-started/map-your-organization/organization-info",
      "/settings/plan-&-billing",
      "/access-restricted",
    ];
    const pathname = [
      "/signup",
      "/login/primary",
      "/dashboard",
      "/forgot-password",
      "/ResendEmail",
      "/act/action-plan",
      "/transform/reporting/reports",
      "/transform/reporting-framework",
      "/VerifyEmail",
      "/resend_mail/:mail",
      "/activate_account",
      "/get-started",
      "/login/secondary",
      "/NewPassword/primary",
      "/VisualAnalytics",
      "/overview/dashboard",
      "/settings/plan&billing/",
      "/payment/success",
      "/payment/fail",
      "/transform/hub/industry-benchmarking",
      "/transform/hubdetail/industry-benchmarking",
      "/transform/hub/integrations",
      "/transform/hub/marketplace",
      "/transform/hub/materiality-matrix",
      "/transform/reporting/reporting-framework",
      "settings/Profile",
      "/transform/hub/marketlace/details",
      "/climate-concierge/portfolio-manager",
      "/climate-concierge/ESG-data-consultant",
      "/climate-concierge/sustainability-advisor",
      "/act/forecast-Analysis",
      "/settings/profile",
      "/settings/organization-info",
      "/pdfview",
      "/unsubscribe",
      "/subscription-success",
      "/measure/all-unsdg-goals",
      "/transform/reporting/selected-unsdgs",
      "/intermodal",
      "/measure/log-your-esg-factors/all",
      "/measure/log-your-esg-factors/all/",
      "/measure/log-your-esg-factors/core",
      "/measure/log-your-esg-factors/core/",
      "/measure/log-your-esg-factors/environment",
      "/measure/log-your-esg-factors/social",
      "/measure/log-your-esg-factors/governance",
      "/measure/log-your-esg-factors/completed",
      "/measure/log-your-esg-factors/archived",
      "/measure/log-your-esg-factor/energy-consumption",
      "/measure/log-your-esg-factor/shipping-and-freight",
      "/measure/climatic-goals",
      "/settings/plan&billing/select-plan",
      "/settings/plan&billing/confirm-billing",
      "/settings/plan&billing/invoices",
      "/settings/users",
      "/get-started/map-your-organization/organization-info",
      "/settings/plan-&-billing",
      "/access-restricted",
    ];

    const dynamicInternalDashboardPaths = [
      "/measure/log-your-esg-factor/details",
      "/transform/hub/marketplace/details",
      "/activate_account",
      "/resend_mail",
      "/NewPassword",
    ]

    const isShowHeader = () => {
      for (let dashbaordPathname of dynamicInternalDashboardPaths) {
        if (window.location.pathname.includes(dashbaordPathname)) {
          return false;
        }
      }

      if (!pathname.includes(window.location.pathname)) {
        return true
      }
      
      if (!footerPathname.includes(window.location.pathname)) {
        return true
      }
    }

    const isShowFooter = () => {
      if (window.location.pathname.length > 30) {
        return false
      }
      if (!footerPathname.includes(window.location.pathname)) {
        return true
      }
    }

    return (
      <div style={{ height: 'auto', width: '100%' }}>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            {isShowHeader() && <Header isMobile={isMobile} />}
            <div style={{ minHeight: "calc(100vh - 656px)" }}>
              {WebRoutesGenerator({ routeMap })}
            </div>
            {isShowFooter() && <Footer />}
          </ThemeProvider>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default App;
