import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";

interface AssigneeOption1 {
  label: string;
  value: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  activeStep:number;
  tableData:string[];
  createTaskForm:boolean;
  categories:string[];
  taskNameValue:string;
  subCategoryValue:string | undefined;
  assigneeValue:AssigneeOption1[];
  startDate:string;
  endDate:string;
  criticalValue:string | undefined;
  description:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GoalManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCategoryId:string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      activeStep:0,
      tableData:[],
      createTaskForm:false,
      categories:[],
      taskNameValue:'',
      subCategoryValue:'',
      assigneeValue:[],
      startDate:'',
      endDate:'',
      criticalValue:'',
      description:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )
      this.getCategoryRespose(requestCallId,message)
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getCategory();
  }

  getStepContent = (step: number)=> {
    switch (step) {
      case 0:
        return "Step 1";
      case 1:
        return "Step 2";
      case 2:
        return "Step 3";
      default:
        return "Unknown step";
    }
  }
  handleCloseCreateTaskForm = () => {
    this.setState({
      createTaskForm:false
    })
  }
  handleCreateTaskForm = () => {
    this.setState({
      createTaskForm:true
    })
  }

  getCategory = async() => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData("authToken")
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoryId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");
    const orgDetails = JSON.parse(localStorage.getItem("organization") || "{}");

    const body = {
      account_id:userDetails.meta.id,
      company_id:orgDetails.id
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCategoryRespose = (requestCallId:string,message:Message) => {
    if(this.getCategoryId === requestCallId){
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    )
    if(responseJson.categories){
          this.setState({
            categories:responseJson.categories
          })
    }
    }
  }
  handleChangeTaskName = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      taskNameValue:event.target.value
    })
  }
  handleStartDate = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      startDate:event.target.value

    })
  }
  handleChangeEndDate = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      endDate:event.target.value
    })
  }
  handleChangeDescription = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      description:event.target.value
    })
  }
  // Customizable Area End
}
