import React, { CSSProperties } from "react";
import {
  createStyles,
  FormControl,
  TextField,
  Typography,
  withStyles,
  WithStyles,
  Button,
  styled,
  Box,
  Avatar,
  Grid,
  Paper,
  Modal,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import ScheduleIcon from "@material-ui/icons/Schedule";
import Layout from "../Layout.web";
import SelectFieldStyled from "../common/SelectFieldStyled.web";
import DashboardController from "../DashboardController";
import CheckboxFieldStyled from "../common/CheckboxFieldStyled.web";
import RadioFieldStyled from "../common/RadioFieldStyled.web";
import CalenderSelectField from "../common/CalenderSelectField.web";
import StyledSlider from "../common/StyledSlider.web";
import CloseIcon from '@material-ui/icons/Close'
import { empty } from "../assets";
import { successLogo }  from  "../assets"
import Tooltip from '@material-ui/core/Tooltip';
import {info} from "../assets"

const Container = styled("div")({
  width: "100%",
  maxWidth: "970px",
  padding: "32px 44px 20px 44px" //  "32px 44px 59px 44px"
});

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "#7D7D7F",
    width: "274.427px",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "160%",
    display: "inline-flex",
    height: "71px",
    padding: "7px 0px 7px 16.048px",
    justifyContent: "flex-end",
    alignItems: "center",
    flexShrink: 0,
    background: "#FFF",
    boxShadow: "0px 5px 25px 0px rgba(0, 0, 0, 0.11)"
  },
}))(Tooltip);

const PageContent = styled(Typography)({
  marginBottom: "23px"
});

const Text = styled(Typography)({
  color: "#7D7D7F",
  textAlign: "left",
  fontFamily: "Lato",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "19.2px"
});

const InlineTextIcon = styled(Text)({
  display: 'flex',
  alignItems: "center",
  justifyContent: "center",
  gap: "8px",
});

const CustomButton = styled(Button)({
  minHeight: "40px",
  minWidth: "97px",
  padding: "8px 8px 8px 12px",
  borderRadius: "8px",
  backgroundColor: "#43D270",
  color: "#34373A",
  textTransform: "none",
  textAlign: "center",
  fontFamily: "Inter",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "19.2px",
  '& span': {
    fontFamily: "Inter",
    fontWeight: 700,
  }
});

const DisabledButton = styled(CustomButton)({
  pointerEvent: "none",
  background: "#B1B3C3"
});

const MainHeading = styled(Typography)({
  color: "#34373A",
  fontFamily: "Lato",
  fontSize: "22px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "27.5px"
});

const HeadingRow = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "17px"
});

const ActiviySection = styled("div")({
  marginTop: "30px",
});

const ActiviyHeading = styled("div")({
  color: "#0F172A",
  fontFamily: "Lato",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "160%",
  letterSpacing: "0.2px",
  marginBottom: "18px"
});

const ErrorAlert = styled('div')({
  color: "#ff0000",
  lineHeight: 1,
  marginTop: "10px",
})

const CommentSection = styled("div")({
  marginBottom: "24px"
});

const CommentBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between"
});

const AvatarWr = styled(Box)({
  width: "48px",
  display: "flex"
});

const Avatar32 = styled(Avatar)({
  width: "32px",
  height: "32px",
  textTransform: 'uppercase',
  fontSize: "15px",
});

const Avatar24 = styled(Avatar32)({
  width: "24px",
  height: "24px",
});

const ActivityInfo = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  gap: "16px",
  alignItems: "center",
  marginBottom: "8px"
});

const ActivityInfoRight = styled(Box)({
  display: "block",
});

const ProfileName = styled("span")({
  fontFamily: "Lato",
  fontSize: "14px",
  fontWeight: 700,
  color: "#34373A",
  textTransform: 'capitalize',
});

const ProfileCard = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start"
});

const ProfileInfoBox = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  marginBottom: "10px",
});

const TextInput = styled(TextField)({
  width: '100%',
  borderRadius: "8px",
  outline: "1 important",
  color: "#34373A",
  fontFamily: "Lato",
  fontSize: "15px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: 1,
});

const FieldLabel = styled(Typography)({
  fontFamily: "Lato",
  fontWeight: 400,
  fontSize: "15px",
  lineHeight: "24px",
  borderRadius: "8px",
  color: "#101010",
  marginBottom: "8px",
})

const FieldWr = styled(Box)({
  marginBottom: "40px",
  width: "93.5%",
})

const FieldContainer = styled(FormControl)({
  maxWidth: "970px",
  width: "100%",
})

const CircularProgressContainer = styled("div")({
	color: "grey", 
  height: "calc(100vh - 145px)", 
  display: "flex", 
  alignItems: "center", 
  justifyContent: "center",
});

const styles = createStyles({
  textFieldStyle: {
    '& *': {
      borderRadius: "8px !important",
      borderColor: "#D9D9D9 !important",
    },
    "& .MuiInputAdornment-positionEnd": {
      marginRight: "12px",
    },
    "& .MuiOutlinedInput-input": {
      marginLeft: "12px",
    },
    height: "48px",
    width: "100%",
  },
  textareaFieldStyle: {
    padding: 0,
    '& *': {
      borderRadius: "8px !important",
      borderColor: "#D9D9D9 !important",
    },
    "& .MuiInputAdornment-positionEnd": {
      marginRight: "12px",
    },
    "& .MuiOutlinedInput-input": {
      marginLeft: "12px",
    },
    width: "100%",
  },
  textStyle: {
    lineHeight: "24px",
    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: "15px",
    borderRadius: "8px",
    color: "#34373A",
    boxSizing: "border-box",
    height: "48px",
    padding: "0 15px",
  },
  muiSelectSelect: {
    padding: "8px 15px"
  },
  helperText: {
    margin: "2px 0 0 0",
    textAlign: "right",
  },
  calculateBtn: {
    position:"relative",
    top:"40px",
    '@media(max-width: 700px)': {
      top:"0px"
    },
  },
  modalBox: {
    display: "flex",
    width: '450px',
    height: '400px',
    padding: "40px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: 'center',
    gap: '32px',
    borderRadius: "16px",
    background: 'var(--additional-white, #FFF)',
    position: 'absolute',
    top: "20%",
    '@media(max-width: 700px)': {
      width: '80%'
    },
  },
  imageAlign: {
    width: "66px",
    height: '129.639px'
  },
  successMsg: {
    alignSelf: "stretch",
    color: "var(--greyscale-900, #0F172A)",
    textAlign: "center",
    fontFamily: 'Montserrat',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '125%',
    letterSpacing: '0.2px'
  },
  successLine: {
    alignSelf: "stretch",
    color: '#34373A',
    textAlign: 'center',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Lato",
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '26px',
    letterSpacing: '0.085px'
  },
  typoText: {
    color: 'var(--greyscale-900, #0F172A)',
    textAlign: 'center',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '160%',
  },
  nextTypo: {
    color: '#43D270',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '160%',
    cursor: 'pointer'
  },
  divAlign: {
    display:"flex",
    '@media(max-width: 700px)': {
     flexDirection:"column"
    },
  },
  clockIcon: {
    width: "16px",
    height: "16px"
  }
});

export const getFieldOptions = (originalArray: any, field1: string = "id", field2: string = "name") => {

  if (originalArray && originalArray.length > 0) {
    return originalArray.map((item: { [x: string]: any; id: any; }) => {
      return {
        id: item.id,
        value: item[field1],
        label: item[field2]
      };
    });
  }

  return originalArray;
}

export const getTimeAgo = (dateStr: string) => {
  const currentDate: any = new Date();
  const commentDate: any = new Date(dateStr);

  if (isNaN(commentDate) || commentDate > currentDate) {
    return "";
  }

  const timeDifference = currentDate - commentDate;
  const timeUnits = [
    { unit: "year", threshold: 365 * 24 * 60 * 60 * 1000 },
    { unit: "month", threshold: 30 * 24 * 60 * 60 * 1000 },
    { unit: "day", threshold: 24 * 60 * 60 * 1000 },
    { unit: "hour", threshold: 60 * 60 * 1000 },
    { unit: "minute", threshold: 60 * 1000 },
    { unit: "second", threshold: 1000 },
  ];

  for (const { unit, threshold } of timeUnits) {
    const unitValue = timeDifference / threshold;
    if (unitValue >= 1) {
      const roundedValue = Math.floor(unitValue);
      return `${roundedValue} ${unit}${roundedValue !== 1 ? "s" : ""} ago`;
    }
  }

  return "just now";
}
interface QuestionData {
  answers: number;
  question_id: number;
  question_type: string;
}

export const getAnswer = (questionId: number, data: QuestionData[], defaultValue: any): number | string => {
  const item = data.find((item) => item.question_id == questionId);
  return item ? item.answers : defaultValue;
};

export interface CustomizedFormProps extends WithStyles<typeof styles> { }

interface Answer {
  answer: string;
  question_index: number;
}

export class EsgFactorsDetails extends DashboardController {
  constructor(props: any) {
    super(props);
  }

  async componentDidMount() {
    this.fetchEsgFactorDetails();
  }
  renderProfileImage = (profileData: any) => {
    let data = profileData?.photo
    if(data){
      return <Avatar32 src={data}  alt=""/>
    }else{
     return <Avatar32>{profileData?.first_name?.['0']} </Avatar32>
    }
  }

  renderQuestion(qitem: any, esgFactorDetailsForm: any, esgFactorQuestionAnswers: any, classes: any) {
    const tempSelectVal = qitem.answer[qitem.question_type] || qitem.answer;
    const ddValue = getAnswer(qitem.id, esgFactorQuestionAnswers, tempSelectVal);
    const tempRadioVal = tempSelectVal || qitem.answer['dropdown'];  // Getting "dropdown" in old answers
    const radioValue = getAnswer(qitem.id, esgFactorQuestionAnswers, tempRadioVal);
    
    switch (qitem.question_type) {
      case 'checkbox':
        return (
          <CheckboxFieldStyled
            data-test-id={`check_field`}
            name={`checkbox_${qitem.id}`}
            onChange={(e: any) => this.esgFactorsQuestionChange(e, qitem.id, qitem.question_type)}
            value={esgFactorQuestionAnswers.length > 0 ? esgFactorQuestionAnswers[0].answers : qitem.answer}
            options={getFieldOptions(qitem.question_options, 'option_id', 'option')}
            label={qitem.question}
          />
        );
      case 'radio':
        return (
          <RadioFieldStyled
            data-test-id={`radio_field`}
            name={`radio_${qitem.id}`}
            onChange={(e: any) => this.esgFactorsQuestionChange(e, qitem.id, qitem.question_type)}
            value={radioValue ? radioValue.toString() : ""}
            options={getFieldOptions(qitem.question_options, 'option_id', 'option')}
            label={qitem.question}
          />
        );
      case 'dropdown':
        return (
          <SelectFieldStyled
            data-test-id={`select_field`}
            onChange={(e: any) => this.esgFactorsQuestionChange(e, qitem.id, qitem.question_type)}
            value={ddValue}
            options={getFieldOptions(qitem.question_options, 'option_id', 'option')}
            label={qitem.question}
            placeholder={qitem.question_placeholder ?? "Select"}
            defaultSelectLabel={qitem.question_placeholder ?? "Select"}
            fullwidth={"882px"}
          />
        );
      default:
        return (
          <FieldWr>
            <Grid item xs={12} sm={6} justifyContent="flex-start" style={{ display: "flex",maxWidth:"100%" }}>
            <FieldLabel >{this.handleFieldLabel(qitem)}</FieldLabel>
            {this.state.locationData && 
            <Box style={{display:"flex",justifyContent:"center",alignItems:"center",paddingBottom:"7px",paddingLeft:"5px"}}>
            <LightTooltip title="Description of what the tooltip
                            is about and what user should do
                            to understand the input field">
             <img src={info} alt="" style={{height:"16px", width:"16px"}}/>
            </LightTooltip>
            </Box>}
            </Grid>
            <TextInput
              data-test-id="text_field"
              placeholder={qitem.question_placeholder}
              variant="outlined"
              type="text"
              value={this.state.calculatedData.carbon ? `${this.state.calculatedData.carbon.replace(" kg co2","")}` : esgFactorDetailsForm[`${qitem.id}`] || ddValue}
              onChange={(e: any) => this.esgFactorsQuestionChange(e, qitem.id, 'text')}
              error={false}
              helperText={""}
              inputProps={{
                maxLength: 250,
              }}
              InputProps={{
                classes: {
                  input: classes.textStyle,
                },
                style: {
                  color: "#34373A",
                  borderRadius: "8px !important",
                },
              }}
              className={classes.textFieldStyle}
            />
          </FieldWr>
        );
    }
  }

   energyCalender = (locationData:any, timePeriodValue:any, esgFactorDetailsFormError:any) => {
      if(locationData && timePeriodValue){

    return  <>
     <Grid item xs={12} sm={6} justifyContent="flex-start" style={{ display: "flex" }}>
        <CalenderSelectField
          dataTestId="coreCalId"
          value={timePeriodValue}
          label={"Select Month"}
          placeholder="Select End Date"
          defaultSelectLabel="Select End Date"
          fullwidth={"404px"}
          errorMessage={"Select End Date is a mandatory field."}
        />
        </Grid> 
        </>
          }else{

          return  <>{locationData && <Grid item xs={12} sm={6} justifyContent="flex-start" style={{ display: "flex" }}>
        <CalenderSelectField
          dataTestId="coreCalId2"
          value={timePeriodValue}
          onChange={(e: any) => this.esgFactorsFieldChange(e, "timePeriod", 'dropdown')}
          label={"Select Month"}
          placeholder="Select End Date"
          defaultSelectLabel="Select End Date"
          fullwidth={"404px"}
          error={esgFactorDetailsFormError && !timePeriodValue}
          errorMessage={"Select End Date is a mandatory field."}
        />
        </Grid>}</>
          }
  } 


  render() {
    const { classes } = this.props;

    const { esgFactorDetails, esgFactorDetailsForm, esgFactorQuestionAnswers, esgFactorDetailsFormError, esgFactorDetailsFormResponse, locationData } = this.state;   
    const activityLogs = esgFactorDetails.card_logs_data || []
    const factorQuestions = esgFactorDetails.questions || []
    const answerData = esgFactorDetails.general_answer_data;
    const isCompleted = esgFactorDetails.general_answer_data !== null;
    const locationValue = !!esgFactorDetailsForm.location ? esgFactorDetailsForm.location : answerData?.location;
    const timePeriodValue = !!esgFactorDetailsForm.timePeriod ? esgFactorDetailsForm.timePeriod : answerData?.time_period;
    const user = JSON.parse(localStorage.getItem("userDetails") as string);
    const esgFactorsStatus = this.props.navigation.getParam("status");
    const isArchived = esgFactorDetails.card_status === "archived";
    const showStyledSliderError = this.account_type !== "secondary_user" && this.state.esgFactorDetailsFormError;

    let activityValue = esgFactorDetailsForm.activity;
    let pointerStyle = { pointerEvents: "unset" }
    
    if (isArchived) {
      pointerStyle = { pointerEvents: "none" };
    }

    if (this.state.loading) {
      return (
        <Layout>
          <CircularProgressContainer>
            <CircularProgress data-test-id="spinner" color="inherit" />
          </CircularProgressContainer>
        </Layout>
      );
    }

    return (
      <Layout>
        <Container data-test-id="page-container" style={pointerStyle as CSSProperties}>
          <Modal open={this.state.carbonFail.show} onClose={this.handleClose} style={webStyle.modalStyle}>
            <Paper style={webStyle.paperStyle as CSSProperties}>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <IconButton onClick={this.handleClose}><CloseIcon></CloseIcon></IconButton>
              </div>
              <div style={{ display: 'grid', placeItems: 'center' }}>
                <img src={empty}
                  style={{ height: "183px", width: "198px" }}
                />
                <Typography style={webStyle.accountemailmaintitle as CSSProperties}>
                  {this.state.carbonFail.message}
                </Typography>
              </div>
            </Paper>
          </Modal>
          <PageContent>
            <HeadingRow>
              <MainHeading>{esgFactorDetails?.card_title}</MainHeading>
              {isArchived ?
                <DisabledButton data-test-id="save_button">Save</DisabledButton>
                :
                <CustomButton onClick={() => this.saveEsgFactorDetails()} data-test-id="save_button">Save</CustomButton>
              }
            </HeadingRow>
            <Text>{esgFactorDetails?.card_description}</Text>
          </PageContent>

          <FieldContainer>

            {!locationData && <>{factorQuestions.map((qitem: any) => (
              this.renderQuestion(qitem, esgFactorDetailsForm, esgFactorQuestionAnswers, classes)
            ))} </>}
             <Grid className={classes.divAlign}>
             {    this.energyCalender( locationData, timePeriodValue, esgFactorDetailsFormError) } 
                {this.state.additionalQuestions && 
            <Grid item xs={12} sm={6} justifyContent="flex-start" style={{ display: "flex", paddingTop:"2px" }}>
              <FieldWr>
                <Box style={{display:"flex"}}>
            <FieldLabel>Kindly input your total energy use for last month</FieldLabel>
            <Box style={{display:"flex",justifyContent:"center",alignItems:"center",paddingBottom:"7px",paddingLeft:"5px"}}>
            <LightTooltip title="Description of what the tooltip
                            is about and what user should do
                            to understand the input field">
             <img src={info} alt="" style={{height:"16px", width:"16px"}}/>
            </LightTooltip>
            </Box>
            </Box>
            <TextInput
              data-test-id="text_field"
              placeholder={"Eg.123..."}
              variant="outlined"
              type={this.state.additionalQuestions.param_type}
              value={this.state.consumptionValue}
              onChange={this.handleConsumption}
              onKeyPress={this.handleKeyPress}
              error={true}
              helperText={""}
              inputProps={{
                maxLength: 250,
              }}
              InputProps={{
                classes: {
                  input: classes.textStyle,
                },
                style: {
                  color: "#34373A",
                  borderRadius: "8px !important",
                },
              }}
              className={classes.textFieldStyle}
            />
            {this.state.consumptErr ?
                (<ErrorAlert>It is mandatory field.</ErrorAlert>)
                : ""
              }
          </FieldWr>
          
              </Grid>}
              </Grid>
              

            

           { locationData ? (<Grid container spacing={2}>
               <Grid item xs={12} sm={6} >
                <SelectFieldStyled
                  data-test-id="location_field"
                  onChange={(e: any) => this.esgFactorsFieldChange(e, "location", 'dropdown')}
                  value={locationValue ? parseInt(locationValue) : ""}
                  options={this.getFieldOptions2(locationData)}
                  label={"Select Location"}
                  placeholder="Select form list"
                  defaultSelectLabel="Select form list"
                  fullwidth={"404px"}
                  error={esgFactorDetailsFormError && !locationValue}
                  errorMessage={"Location is a mandatory field."}
                />
              </Grid>
              <Grid item xs={12} sm={6} justifyContent="flex-start" alignItems="center" className={classes.calculateBtn}>
                <CustomButton style={{width:"207px" ,height:"40px",marginBottom:"4px"}}
                onClick={this.calculateApi}>Calculate</CustomButton>
                </Grid>
                
            </Grid>) : (
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <SelectFieldStyled
                    data-test-id="location_field1"
                    noMarginBottom
                    noFixedWidth
                    onChange={(e: any) => this.esgFactorsFieldChange(e, "location", 'dropdown')}
                    value={locationValue ? parseInt(locationValue) : ""}
                    options={getFieldOptions(esgFactorDetails.locations)}
                    label={"Select Location"}
                    placeholder="Select"
                    defaultSelectLabel="Select"
                    fullwidth={"404px"}
                    error={esgFactorDetailsFormError && !locationValue}
                    errorMessage={"Location is a mandatory field."}
                  />
                </Grid>
                <Grid item md={6} xs={12}></Grid>
                {
                  this.state.cardType === "materiality" && (
                    <>
                      <Grid item md={6} xs={12}>
                        <StyledSlider 
                          data-test-id="itc-slider"
                          name="importanceToCompany"
                          label="Select Importance to Company" 
                          onChange={this.handleSliderChange}
                          value={this.state.importanceToCompany}
                          error={showStyledSliderError}
                          disabled={this.account_type === "secondary_user"}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <StyledSlider 
                          data-test-id="its-slider"
                          name="importanceToStakeHolders"
                          label="Select Importance to Stakeholders"
                          onChange={this.handleSliderChange}
                          value={this.state.importanceToStakeHolders}
                          error={showStyledSliderError}
                          disabled={this.account_type === "secondary_user"}
                        />
                      </Grid>
                    </>
                  )
                }
              </Grid>
            )}

                <Modal
          open={esgFactorDetailsFormResponse}
          onClose={this.handleClose}
          style={{display:"flex",alignItems:"center",justifyContent:"center"}}
          >
          <Box  className={classes.modalBox} >
            <img src={successLogo} className={classes.imageAlign} alt=""/>
            <Typography className={classes.successMsg} >Success!</Typography>
            <Typography className={classes.successLine} >You've successfully updated the card. way to go !</Typography>
            <Typography className={classes.typoText}>Add more emissions. Click <span className={classes.nextTypo}  onClick={() => this.handleNavigate()} data-test-id="navigate" >Next</span></Typography>
          </Box>
        </Modal>
              
            <Grid container spacing={2}>
              {locationData && <Grid item xs={12} sm={6} justifyContent="flex-start" style={{ display: "flex" }}>
              {factorQuestions.map((qitem: any) => (
              this.renderQuestion(qitem, esgFactorDetailsForm, esgFactorQuestionAnswers, classes)
            ))}
            </Grid>}
             
              </Grid>
          </FieldContainer>

          <ActiviySection>
            <ActiviyHeading>Activity</ActiviyHeading>
            <CommentSection>
              <CommentBox>
                <AvatarWr>
                  {this.renderProfileImage(user?.meta)}
                </AvatarWr>
                <TextInput
                  data-test-id="activity_field"
                  name="activity"
                  multiline
                  value={activityValue}
                  placeholder="Add a comment..."
                  variant="outlined"
                  type="textarea"
                  FormHelperTextProps={{
                    className: classes.helperText
                  }}
                  error={esgFactorDetailsForm.activity > 250}
                  helperText={"Max length 250"}
                  InputProps={{
                    classes: {
                      input: classes.textStyle,
                    },
                    style: { color: "#34373A", borderRadius: "8px !important", padding: "12px 14px" },
                  }}
                  className={classes.textareaFieldStyle}
                  onChange={(e: any) => this.esgFactorsFieldChange(e, "activity", 'text')}
                />
              </CommentBox>
            </CommentSection>

            {activityLogs && activityLogs.map((item: any) => {
              const timeAgo = getTimeAgo(item.answered_date_time);
              return <ProfileCard>
                <ProfileInfoBox>
                  <AvatarWr>
                    {item.image ?
                      <Avatar24
                        src={item.image}
                        alt={item.answered_by}
                      /> :
                      <Avatar24>{item.answered_by[0]}</Avatar24>
                    }
                  </AvatarWr>
                  <ActivityInfoRight>
                    <ActivityInfo>
                      <ProfileName>{item.answered_by}</ProfileName>
                      <InlineTextIcon>
                        <ScheduleIcon className={classes.clockIcon} />
                        {timeAgo}
                      </InlineTextIcon>
                    </ActivityInfo>
                    {item.answer.map((answer: Answer) => {
                      return (
                        <Text>Answered "{answer.answer}" for Question {answer.question_index}</Text>
                      );
                    })}
                    {item.itc && <Text>Set Importance to company: {item.itc}</Text>}
                    {item.its && <Text>Set Importance to stakeholders: {item.its}</Text>}
                    {item.comment && <Text>Commented: {item.comment}</Text>}
                  </ActivityInfoRight>
                </ProfileInfoBox>

              </ProfileCard>
            })}

          </ActiviySection>
        </Container>
      </Layout>
    );
  }
}

const webStyle = {
  accountemailmaintitle: {
    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "28px",
    textAlign: "center",
    letterSpacing: "0.2px",
    color: "#34373A",
  },
  modalStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperStyle: {
    width: '460px',
    maxWidth: "100%",
    minHeight: '300px',
    padding: '20px 20px 40px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
  },
};

export default withStyles(styles)(EsgFactorsDetails);
